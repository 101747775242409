const URL = {
  NOT_FOUND: '/404',
  OTHER: '*',
  GLOBAL: '/',
  DAY_TO_DAY: '/d2d',
  PERFORMANCE_MANAGEMENT: '/performance_management',
  EQUIPMENT_MANAGEMENT: '/equipment_management',
  QUALITY_MANAGEMENT: '/quality_management',
  CBM: '/cbm',
  ORDER_MANAGEMENT: '/order_management',
  DATA_REPORTING: '/data_report',
  DEMO_DATA_SIMULATOR: '/demo_data_simulator',
  ACCESS: '/access',
  GENERAL_CONFIG: '/general_config',
  FACTORY_HEALTH: '/factory_health',
  ACCESS_CONFIG: '/accessconfig',
  CONFIG: '/config',
  SOC_CONFIG: '/standard_operating_conditions',
  OEE: '/oee',
  CONNECTIVITY_MDU: '/mdu_connectivity',
  BUSINESS_INTELLIGENCE: '/bi_config',
};

export default URL;
