import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './MuiClassNameSetup';
import { ThemeProvider } from '@mui/material/styles';
import AppSideBar from './containers/AppSideBar';
import { theme } from '@smf/ui-util-app';
import { useRxjsState } from './utils/hooks/useRxjsState';

export default function Root() {
  const { rxjsState } = useRxjsState();
  return (
    <>
      {rxjsState.isLoggedInUser && (
        <Router>
          <ThemeProvider theme={theme}>
            <AppSideBar {...rxjsState} />
          </ThemeProvider>
        </Router>
      )}
    </>
  );
}
