export const SIDEBAR_SCREENS = {
  GLOBE: 'Global Home',
  PERFORMANCE_MANAGEMENT: 'Perfromance Management',
  EQUIPMENT_MANAGEMENT: 'Equipment Managment',
  QUALITY_MANAGEMENT: 'Quality Managment',
  ORDER_MANAGEMENT: 'Order Management',
  CONDITION_MONITORING: 'Condition Monitoring',
  GENERAL_CONFIGURATION: 'General Configuration',
  ACCESS_CONFIGURATION: 'Access Configuration',
  CONSTRAINT_MANAGEMENT: 'Constraint Management',
  CONFIG: 'Config',
  DATA_REPORT: 'Data Report',
  DEMO_DATA_SIMULATOR: 'Demo Data Simulator',
  BUSINESS_INTELLIGENCE: 'Business Intelligence',
};

export const SIDEBAR_TOOLTIP_TEXT = {
  GLOBE: 'Home',
  PERFORMANCE_MANAGEMENT: 'Operations',
  EQUIPMENT_MANAGEMENT: 'Maintenance',
  QUALITY_MANAGEMENT: 'Quality',
  CONFIGURATION: 'Factory Configuration',
  SYSTEM_ADMINISTRATION: 'System Administration',
  DATA_REPORTS: 'Data Reports',
  DEMO_DATA_SIMULATOR: 'Demo Data Simulator',
  BUSINESS_INTELLIGENCE: 'Business Intelligence',
};

export const NAVIGATION_IDS = {
  BI_CONFIG: 'bi_config',
};
