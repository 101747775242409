import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, Toolbar } from '@mui/material';

function SideBar({
  children,
  variant = 'permanent',
  nonToolbar,
  modalProps = {},
  paperProps = {},
  customDrawerClasses = {},
  customStyles = {},
}) {
  return (
    <>
      <Drawer
        variant={variant}
        ModalProps={{ ...modalProps }}
        PaperProps={{ ...paperProps }}
        classes={customDrawerClasses}
        sx={customStyles}
      >
        {!nonToolbar && <Toolbar />}
        {children}
      </Drawer>
    </>
  );
}

SideBar.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  nonToolbar: PropTypes.bool,
  modalProps: PropTypes.objectOf(PropTypes.object),
  paperProps: PropTypes.objectOf(PropTypes.object),
  customDrawerClasses: PropTypes.objectOf(PropTypes.string),
  customStyles: PropTypes.objectOf(PropTypes.object),
};

export default SideBar;
