import { globalstate$ } from '@smf/ui-util-app';

/**
 * Function to Update Global state when user tries to navigate away from FactoryGeneral Page
 * @param {Object} actionObj Object to update FactoryGeneralEditState
 */
export const updateFactoryGeneralEditState = (actionObj = {}) => {
  const state = globalstate$.getValue();
  globalstate$.next({
    ...state,
    factoryGeneralEditState: {
      ...state.factoryGeneralEditState,
      action: {
        ...state.factoryGeneralEditState.action,
        ...actionObj,
      },
    },
  });
};

/**
 * @description function to update the rxjs state for work orders in equipment constraints d2d.
 * used here to reset the state when the user clicks on the icon in the navigation bar.
 * @param {string} entityId
 * @param {boolean} woPageOpen
 */
export const updateWorkOrdersState = (entityId, woPageOpen) => {
  const state = globalstate$.getValue();
  globalstate$.next({
    ...state,
    workOrdersState: {
      ...state.workOrdersState,
      isWOPageOpen: woPageOpen,
      entityID: entityId,
    },
  });
};

export const updateDDSEventState = (actionObj = {}) => {
  const state = globalstate$.getValue();
  globalstate$.next({
    ...state,
    ddsNavigateAwayConfig: {
      ...state.ddsNavigateAwayConfig,
      action: {
        ...state.ddsNavigateAwayConfig.action,
        ...actionObj,
      },
    },
  });
};
