import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
  ListItemButton,
} from '@mui/material';
import SideBar from '../SideBar';
import WhiteDivider from '../WhiteDivider/WhiteDivider';
import { COMPONENT_ID } from '../../utils/componentId';
import GeneralTooltip from '../Tooltip/GeneralTooltip';
import { listItemClasses } from '@mui/material/ListItem';
import { listItemButtonClasses } from '@mui/material/ListItemButton';
import { listItemIconClasses } from '@mui/material/ListItemIcon';
import { typographyClasses } from '@mui/material/Typography';
import { paperClasses } from '@mui/material/Paper';

const NavListItem = styled(ListItem)(() => ({
  [`&.${listItemClasses.root}`]: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
}));

const NavIconButton = styled(ListItemButton)(({ theme }) => ({
  [`&.${listItemButtonClasses.root}`]: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.dullDark,
      borderLeft: `.3rem solid ${theme.palette.selected.greenSelect}`,
    },
    '&.Mui-disabled': {
      opacity: 0.5,
    },
  },
}));

const NavListItemBox = styled(Box)(() => ({
  margin: '0 0.5em',
}));

const NavListItemIcon = styled(ListItemIcon)(() => ({
  [`&.${listItemIconClasses.root}`]: {
    minWidth: 0,
    minHeight: 0,
  },
}));

const NavListItemTypography = styled(Typography)(() => ({
  [`&.${typographyClasses.root}`]: {
    lineHeight: '1em',
    fontSize: '0.75em',
  },
}));

function MainSideBar({ children }) {
  return (
    <>
      <SideBar
        customStyles={{
          [`& .${paperClasses.root}`]: {
            borderRight: 0,
          },
        }}
      >
        {children}
      </SideBar>
    </>
  );
}

function DefaultList({
  options,
  showText,
  selectedOption,
  onMenuItemClick,
  isTopMarginRequired = false,
  ...rest
}) {
  return (
    <List
      sx={{
        p: isTopMarginRequired ? '1em 0' : {},
      }}
      {...rest}
      data-testid={COMPONENT_ID.MAIN_SIDEBAR_LIST}
    >
      {options.map((option, idx) => (
        <NavListItem
          key={option.key}
          data-testid={`${COMPONENT_ID.MAIN_SIDEBAR_ITEM}-${idx}`}
          id={`${COMPONENT_ID.MAIN_SIDEBAR_ITEM}-${idx}`}
        >
          <NavIconButton
            disabled={option.disabled}
            onClick={() => onMenuItemClick(option.key)}
            selected={option.key === selectedOption}
          >
            <GeneralTooltip
              tooltipText={option.tooltipText}
              tooltipPosition="right"
              tooltipDisable={false}
            >
              <NavListItemBox
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <NavListItemIcon>{option.icon}</NavListItemIcon>
                {showText && (
                  <ListItemText>
                    <NavListItemTypography variant="body1">
                      {option.text}
                    </NavListItemTypography>
                  </ListItemText>
                )}
              </NavListItemBox>
            </GeneralTooltip>
          </NavIconButton>
        </NavListItem>
      ))}
    </List>
  );
}

function ListGap({ size }) {
  return <Grid container item xl={size} />;
}

MainSideBar.DefaultList = DefaultList;
MainSideBar.DefaultDivider = WhiteDivider;
MainSideBar.ListGap = ListGap;

MainSideBar.propTypes = {
  children: PropTypes.node,
};

ListGap.propTypes = {
  size: PropTypes.number,
};

DefaultList.propTypes = {
  rest: PropTypes.arrayOf(),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      disabled: PropTypes.bool,
      tooltipText: PropTypes.string,
    })
  ),
  showText: PropTypes.bool,
  selectedOption: PropTypes.string,
  onMenuItemClick: PropTypes.func.isRequired,
  isTopMarginRequired: PropTypes.bool,
};

export default MainSideBar;
