import { Divider, alpha } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: alpha(theme.palette.divider, 0.3),
}));

export default function WhiteDivider({ ...other }) {
  return <StyledDivider variant="middle" {...other} />;
}
