import { globalstate$ } from '@smf/ui-util-app';
import { useEffect, useState } from 'react';

export const useRxjsState = () => {
  const [rxjsState, setState] = useState({});

  useEffect(() => {
    if (globalstate$) {
      const subscription = globalstate$.subscribe((globalstate) => {
        setState(globalstate);
      });

      return () => {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    }
  }, []);

  // Function for Changing State : Demo function
  function changePlantName(plantName) {
    globalstate$.next({ ...rxjsState, plantName });
  }

  return { rxjsState, changePlantName };
};
