import MainSideBar from '../../components/MainSideBar';
import GlobeIcon from '../../assets/img/Globe.svg';
import PMIcon from '../../assets/img/PMIcon.svg';
import CBMIcon from '../../assets/img/CBMIcon.svg';
import QMICon from '../../assets/img/QMIcon.svg';
import AccessConfigIcon from '../../assets/img/accessConfig.svg';
import ConfigIcon from '../../assets/img/configNut.svg';
import dataReportingIcon from '../../assets/img/dataReportingIcon.svg';
import DdsIcon from '../../assets/img/ddsIcon.svg';
import BIConfigIcon from '../../assets/img/biConfigIcon.svg';
import URL from '../../utils/urlEndpoints';
import {
  NAVIGATION_IDS,
  SIDEBAR_SCREENS,
  SIDEBAR_TOOLTIP_TEXT,
} from '../../constants/en-us';
import { useEffect, useState } from 'react';
import { navigateToUrl } from 'single-spa';
import { useLocation } from 'react-router-dom';
import {
  RBAC_BUTTONS,
  RBACValidation,
  globalstate$,
  getHighestRBACStatus,
  updateEditState,
  EDIT_STATE_KEYS,
} from '@smf/ui-util-app';
import {
  updateFactoryGeneralEditState,
  updateWorkOrdersState,
  updateDDSEventState,
} from '../../utils/rxjsStateNext';

function AppSideBar(props) {
  const { showOldUI, userStatus, plantId, isFGCComplete, userData } = props;
  console.info('showOldUI', showOldUI);
  const location = useLocation();
  const [page, setPage] = useState(location.pathname);

  const [hasFactoryConfigAccess, setHasFactoryConfigAccess] = useState(false);
  const [hasSystemAdminAccess, setHasSystemAdminAccess] = useState(false);
  const [hasPlantAccess, setHasPlantAccess] = useState(false);
  const [isD2dScreenEnabled, setIsD2dScreenEnabled] = useState(false);

  useEffect(() => {
    //Clearing plant/bu information in global state when landing on home page
    if (location.pathname === URL.GLOBAL) {
      const globalRoleValue = getHighestRBACStatus(userData.groups);
      globalstate$.next({
        ...globalstate$.value,
        plantId: '',
        plantName: '',
        factoryId: null,
        businessUnit: '',
        plantRole: '',
        isFGCComplete: false,
        userStatus: globalRoleValue,
      });
    }

    const pathnameComponents = location.pathname.split('/');
    const selectedPage =
      pathnameComponents[1] === URL.CONFIG.slice(1)
        ? pathnameComponents[1]
        : pathnameComponents[pathnameComponents.length - 1];
    setPage(`/${selectedPage}`);
  }, [location.pathname]);

  // single place to handle all show/hide/disable navigation icon logic
  // for easier unit testing
  const handleNavigationAccess = (userStatus, plantId, isFGCComplete) => {
    setIsD2dScreenEnabled(!!plantId && isFGCComplete);
    setHasFactoryConfigAccess(
      RBACValidation('Button', RBAC_BUTTONS['FACTORY_CONFIG'], userStatus) &&
        !!plantId
    );
    setHasPlantAccess(
      RBACValidation('Button', RBAC_BUTTONS['PLANT_ADMIN'], userStatus) &&
        !!plantId
    );
    plantId
      ? setHasSystemAdminAccess(
          RBACValidation('Button', RBAC_BUTTONS['PLANT_ADMIN'], userStatus)
        )
      : setHasSystemAdminAccess(
          RBACValidation('Button', RBAC_BUTTONS['SYSTEM_ADMIN'], userStatus)
        );
  };

  useEffect(() => {
    handleNavigationAccess(userStatus, plantId, isFGCComplete);
  }, [userStatus, plantId, isFGCComplete]);

  // Icon Navigation Onclick Handler
  let onMenuItemclick = (key) => {
    if (key === URL.GLOBAL) {
      sessionStorage.removeItem('redirectTo');
    }
    // ? if user clicks on equipment management/any d2d icon,
    // ? any persisting data related to WO should be cleared to ensure the default view in equipment constraints d2d screen
    updateWorkOrdersState('', false);

    if (
      location.pathname === URL.DEMO_DATA_SIMULATOR &&
      globalstate$?._value.ddsNavigateAwayConfig?.isUnsavedChange
    ) {
      updateDDSEventState({
        isUserNavigating: true,
        location: key,
      });
      return;
    }

    if (
      location.pathname === `${URL.CONFIG}${URL.GENERAL_CONFIG}` &&
      globalstate$?._value.factoryGeneralEditState?.isUnsavedChange
    ) {
      updateFactoryGeneralEditState({
        isUserNavigating: true,
        location: key,
      });
      return;
    }
    if (
      location.pathname === `${URL.CONFIG}${URL.SOC_CONFIG}` &&
      globalstate$?._value.socEditState?.isUnsavedRecipe
    ) {
      updateEditState(
        { isUserNavigating: true, location: key },
        {},
        EDIT_STATE_KEYS.SOC_EDIT_STATE
      );
      return;
    }
    key === URL.CONFIG
      ? navigateToUrl(`${URL.CONFIG}${URL.CONNECTIVITY_MDU}`)
      : navigateToUrl(key);
    setPage(key);
  };

  return (
    <MainSideBar>
      <MainSideBar.DefaultList
        id="globe"
        onMenuItemClick={onMenuItemclick}
        options={[
          {
            text: SIDEBAR_SCREENS.GLOBE,
            icon: <img src={GlobeIcon} alt="" />,
            key: URL.GLOBAL,
            disabled: false,
            tooltipText: SIDEBAR_TOOLTIP_TEXT.GLOBE,
          },
        ]}
        selectedOption={page}
      />
      <MainSideBar.DefaultDivider />
      <MainSideBar.ListGap size={5} />
      <MainSideBar.DefaultDivider />

      {hasFactoryConfigAccess && (
        <MainSideBar.DefaultList
          id="config"
          onMenuItemClick={onMenuItemclick}
          options={[
            {
              text: SIDEBAR_SCREENS.CONFIG_CONTAINER,
              icon: <img src={ConfigIcon} alt="" />,
              key: `${URL.CONFIG}`,
              disabled: false,
              tooltipText: SIDEBAR_TOOLTIP_TEXT.CONFIGURATION,
            },
          ]}
          selectedOption={page}
        />
      )}
      {hasSystemAdminAccess && (
        <MainSideBar.DefaultList
          id="access_configuration"
          onMenuItemClick={onMenuItemclick}
          options={[
            {
              text: SIDEBAR_SCREENS.ACCESS_CONFIGURATION,
              icon: <img src={AccessConfigIcon} alt="" />,
              key: `${URL.ACCESS_CONFIG}`,
              disabled: false,
              tooltipText: SIDEBAR_TOOLTIP_TEXT.SYSTEM_ADMINISTRATION,
            },
          ]}
          selectedOption={page}
        />
      )}
      {hasPlantAccess && (
        <MainSideBar.DefaultList
          id="data_reporting"
          onMenuItemClick={onMenuItemclick}
          options={[
            {
              text: SIDEBAR_SCREENS.DATA_REPORT,
              icon: <img src={dataReportingIcon} alt="" />,
              key: `${URL.DATA_REPORTING}`,
              disabled: !isFGCComplete,
              tooltipText: SIDEBAR_TOOLTIP_TEXT.DATA_REPORTS,
            },
          ]}
          selectedOption={page}
        />
      )}
      <MainSideBar.DefaultList
        id="demo_data_simulator"
        onMenuItemClick={onMenuItemclick}
        options={[
          {
            text: SIDEBAR_SCREENS.DEMO_DATA_SIMULATOR,
            icon: <img src={DdsIcon} alt="" />,
            key: `${URL.DEMO_DATA_SIMULATOR}`,
            tooltipText: SIDEBAR_TOOLTIP_TEXT.DEMO_DATA_SIMULATOR,
          },
        ]}
        selectedOption={page}
      />
      {hasPlantAccess && (
        <>
          <MainSideBar.DefaultDivider />
          <MainSideBar.DefaultList
            id={NAVIGATION_IDS.BI_CONFIG}
            onMenuItemClick={onMenuItemclick}
            options={[
              {
                text: SIDEBAR_SCREENS.BUSINESS_INTELLIGENCE,
                icon: <img src={BIConfigIcon} alt="" height={30} width={30} />,
                key: `${URL.BUSINESS_INTELLIGENCE}`,
                tooltipText: SIDEBAR_TOOLTIP_TEXT.BUSINESS_INTELLIGENCE,
              },
            ]}
            selectedOption={page}
          />
        </>
      )}
    </MainSideBar>
  );
}

export default AppSideBar;
