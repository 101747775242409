import { Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import PropTypes from 'prop-types';
import { tooltipClasses } from '@mui/material/Tooltip';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: theme.customColors.eerieBlack,
    border: '0.06rem solid theme.customColors.metalGrey',
    boxShadow: '0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.5)',
    borderRadius: '0.25rem',
    padding: '0.625rem 0.75rem',
    gap: '0.625rem',
    fontColor: theme.customColors.lightYellowishGray,
    fontSize: '0.75rem',
    lineHeight: '1rem',
  },
}));

export default function GeneralTooltip({
  tooltipText,
  tooltipPosition,
  tooltipDisable,
  children,
}) {
  return (
    <StyledTooltip
      title={tooltipDisable ? '' : tooltipText}
      placement={tooltipPosition}
    >
      {children}
    </StyledTooltip>
  );
}

GeneralTooltip.propTypes = {
  tooltipText: PropTypes.string,
  tooltipPosition: PropTypes.string,
  tooltipDisable: PropTypes.bool,
  children: PropTypes.node,
};
